import { FormEvent, useState } from "react";

import { useLogin, useNotify, useTranslate } from "react-admin";
import Auth, { AuthMethod } from "../components/Auth";
import { basePath } from "../providers/env";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const login = useLogin();
  const notify = useNotify();
  const translate = useTranslate();

  const submit = (authMethod: AuthMethod) => {
    if (authMethod === "manual") {
      login({ email, password }).catch((e) => {
        const msg = e.response?.data?.detail;
        if (msg) {
          notify(msg, { type: "error" });
        } else {
          notify(translate("ra.message.error"), { type: "error" });
        }
      });
    } else if (authMethod === "sso") {
      window.location.href = `${basePath}/api/v1/sso/login`;
    }
  };

  return (
    <Auth
      setEmail={setEmail}
      setPassword={setPassword}
      actionName={translate("staffinghrm.actions.sign_in")}
      submit={submit}
    />
  );
};

export default Login;
