import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  SimpleForm,
  TextField,
  FunctionField,
  TextInput,
  usePermissions,
} from "react-admin";

import { Divider, Typography } from "@mui/material";

export const CandidatesQualificationList = (props: any) => {
  const { isLoading, permissions } = usePermissions();

  return (
    <List {...props} filters={[]}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        {!isLoading && permissions.is_superuser && <EditButton />}
      </Datagrid>
    </List>
  );
};

export const CandidatesQualificationEdit = (props: any) => (
  <Edit
    {...props}
    mutationMode="pessimistic"
    title={
      <FunctionField
        render={(record: any) => (
          <Typography variant="h6">
            {`Квалификация кандидата ${record.name}`}
          </Typography>
        )}
      />
    }
  >
    <SimpleForm>
      <FunctionField
        label="ID"
        render={(record: any) => (
          <Typography variant="body1">
            ID квалификации кандидата - {record.id}
          </Typography>
        )}
      />
      <Divider sx={{ marginBottom: 2, marginTop: 1 }} />
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

export const CandidatesQualificationCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);
