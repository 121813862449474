import { FormEvent, ReactElement } from "react";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import TextField from "@mui/material/TextField";
import { Notification, useTranslate } from "react-admin";
import authStyles from "./authStyles";
import LockIcon from "@mui/icons-material/Lock";

interface AuthProps {
  submit(authMethod: AuthMethod): void;
  setEmail(value: string): void;
  setPassword?(value: string): void;
  actionName: string;
  extraActions?: ReactElement;
}

export type AuthMethod = "manual" | "sso";

const Auth: React.FC<AuthProps> = ({
  submit,
  setEmail,
  setPassword,
  actionName,
  extraActions,
}) => {
  const translate = useTranslate();

  return (
    <div style={authStyles.main}>
      <Card sx={authStyles.card}>
        <div style={authStyles.avatar}>
          <Avatar>
            <LockIcon />
          </Avatar>
        </div>
        <CardHeader title={actionName} sx={authStyles.header} />
        <div style={authStyles.form}>
          <div>
            <TextField
              id="email"
              label={translate("staffinghrm.login.email")}
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
          </div>

          {setPassword && (
            <div>
              <TextField
                id="password"
                label={translate("staffinghrm.login.password")}
                type="password"
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          )}
        </div>
        <CardActions sx={authStyles.actions}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => submit("manual" as AuthMethod)}
          >
            {actionName}
          </Button>
        </CardActions>
        <CardActions sx={authStyles.actions}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => submit("sso" as AuthMethod)}
          >
            {translate("staffinghrm.login.enter_sso")}
          </Button>
        </CardActions>
      </Card>
      <Notification />
    </div>
  );
};

export default Auth;
