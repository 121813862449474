import * as colors from "@mui/material/colors";

export enum EventStatus {
  RESUME = "SENRES",
  REJRES = "REJRES",
  INTERW = "INTERW",
  REFINT = "REFINT",
  PASSED = "PASSED",
  INPROJ = "INPROJ",
  ACCEPT = "OFFERACCEPTED",
  DECLIN = "OFFERDECLINED",
  PROJEC = "PROJECTENTRY",
  FORCER = "FORCEREJECT",
  SELF_R = "SELF_REJECT",
}

export const EventStatusColors: { [key in EventStatus]: keyof typeof colors } =
  {
    SENRES: "lime",
    REJRES: "red",
    INTERW: "lightGreen",
    REFINT: "red",
    PASSED: "green",
    INPROJ: "green",
    OFFERACCEPTED: "green",
    OFFERDECLINED: "red",
    PROJECTENTRY: "green",
    FORCEREJECT: "orange",
    SELF_REJECT: "red",
  };
