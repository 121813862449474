import {
  AutocompleteInput,
  BulkActionProps,
  BulkDeleteButton,
  ButtonProps,
  Create,
  Datagrid,
  DateField,
  FileField,
  FileInput,
  List,
  RaRecord,
  required,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useCreate,
  useDataProvider,
  useListContext,
  useNotify,
  useRefresh,
  useTranslate,
  WithRecord,
} from "react-admin";
import { Box, Button, DialogTitle, Dialog } from "@mui/material";
import { ReactElement, useCallback, useState } from "react";
import { basePath } from "../providers/env";
import { useDropzone } from "react-dropzone";
import { Download as DownloadIcon } from "@mui/icons-material";
import { useParams } from "react-router";
import { downloadFileAuthorized } from "../providers/dataProvider";

interface CreateArtifactModalProps {
  onToggle(val: boolean): void;
  opened: boolean;
  defaultValue: any;
  refresh(): void;
}

const CreateArtifactModal = (props: CreateArtifactModalProps) => {
  const { id: candidateId } = useParams();
  const notify = useNotify();
  const translate = useTranslate();
  return (
    <Dialog fullWidth open={props.opened} onClose={() => props.onToggle(false)}>
      <DialogTitle>
        {translate("staffinghrm.artifacts.dialog.title")}
      </DialogTitle>
      <Create
        resource="artifacts"
        transform={(data) => {
          data = {
            ...data,
            candidate_id: candidateId,
          };
          let fd = new FormData();
          for (const [key, val] of Object.entries(data))
            fd.append(
              key,
              key === "artifact_file"
                ? ((val as any)?.rawFile as File)
                : `${val}`
            );

          return fd;
        }}
        redirect={false}
        mutationOptions={{
          onSuccess: () => {
            props.onToggle(false);
            props.refresh();
            notify("ra.notification.created");
          },
        }}
      >
        <SimpleForm
          toolbar={
            <Toolbar>
              <SaveButton />
            </Toolbar>
          }
        >
          <FileInput
            name="artifact_file"
            source="src"
            validate={required()}
            defaultValue={props.defaultValue}
          >
            <FileField source="src" title="title" target="_blank"></FileField>
          </FileInput>
          <TextInput source="comment" name="comment" />
        </SimpleForm>
      </Create>
    </Dialog>
  );
};

export const CandidatesArtifactList = () => {
  const [opened, setOpened] = useState(false);
  const [defaultFile, setDefaultFile] = useState<any>(null);
  const { id: candidateId } = useParams();
  const refresh = useRefresh();
  const translate = useTranslate();

  const transformFile = (file: File) => {
    if (!(file instanceof File)) {
      return file;
    }

    const preview = URL.createObjectURL(file);
    const transformedFile = {
      rawFile: file,
      src: preview,
      title: file.name,
    };

    return transformedFile;
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setDefaultFile(transformFile(acceptedFiles[0]));
    setOpened(true);
    setDefaultFile(null);
  }, []);
  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    noClick: true,
  });

  return (
    <Box {...getRootProps()}>
      <TopToolbar>
        <Button
          onClick={() => {
            downloadFileAuthorized(
              `${basePath}/api/v1/artifacts/archive?candidate=${candidateId}`
            );
          }}
        >
          {translate("staffinghrm.actions.download")}
        </Button>
        <Button variant={"contained"} onClick={() => setOpened(true)}>
          {translate("staffinghrm.actions.add")}
        </Button>
      </TopToolbar>
      {isDragActive && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
            backgroundColor: "#fafafb",
            borderRadius: "x2",
            cursor: "pointer",
            padding: "8px",
            textAlign: "center",
          }}
        >
          <DownloadIcon style={{ width: "30px", height: "30px" }} />
          {translate("staffinghrm.input.dropzone_message_active")}
        </Box>
      )}

      <List
        title=" "
        filter={{ candidate: candidateId }}
        disableSyncWithLocation
        resource="artifacts"
        pagination={false}
        perPage={99999}
        component="div"
        actions={false}
      >
        <Datagrid
          bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
        >
          <TextField source="file_name" sortBy="file__file_name" />
          <TextField source="comment" />
          <DateField source="created" />
          <WithRecord
            render={(artifactRecord) => (
              <Button
                onClick={() => {
                  downloadFileAuthorized(
                    `${basePath}/api/v1/artifacts/${artifactRecord?.id}/file`
                  );
                }}
              >
                {translate("staffinghrm.actions.download")}
              </Button>
            )}
          />
        </Datagrid>
      </List>
      <CreateArtifactModal
        opened={opened}
        onToggle={setOpened}
        defaultValue={defaultFile}
        refresh={refresh}
      />
    </Box>
  );
};
