// in src/i18n/en.js
import englishMessages from "ra-language-english";
import { EventStatus, VacancyStatus } from "../../dicts";

export const en = {
  ...englishMessages,
  resources: {
    users: {
      name: "User |||| Users",
      fields: {
        id: "Id",
        first_name: "First name",
        last_name: "Last name",
        full_name: "Full name",
        email: "Email",
        is_active: "Is active",
        is_superuser: "Is superuser",
        password: "Password",
      },
    },
    "candidate-qualifications": {
      name: "Candidate qualification |||| Candidate qualifications",
      fields: {
        id: "Id",
        name: "Name",
      },
    },
    "candidate-types": {
      name: "Candidate type |||| Candidate types",
      fields: {
        id: "Id",
        name: "Name",
      },
    },
    account: {
      name: "Account |||| Accounts",
      fields: {
        id: "Id",
        name: "Name",
      },
    },
    project: {
      name: "Project |||| Projects",
      fields: {
        id: "Id",
        name: "Name",
        description: "Description",
        account_id: "Account",
      },
    },
    candidate: {
      name: "Candidate |||| Candidates",
      fields: {
        id: "Id",
        surname: "Surname",
        name: "Name",
        patronymic: "Patronymic",
        type_id: "Type",
        qualification_id: "Qualification",
        expectation: "Expectation",
        created: "Created at",
      },
    },
    vacancy: {
      name: "Vacancy |||| Vacancies",
      fields: {
        account_id: "Account",
        project_id: "Project",
        candidate_type_id: "Candidate type",
        candidate_qualification_id: "Candidate qualification",
        quantity: "Quantity",
        salary_fork: "Salary fork",
        "head_of_vacancy.full_name": "Head of vacancy",
        head_of_vacancy_id: "Head of vacancy",
        "head_of_vacancy.id": "Head of vacancy",
        expectation: "Expectation",
        comment: "Comment",
        user_ids: "HR-s",
        description: "Decription",
        "created_by.full_name": "Created by",
        status: "Status",
      },
    },
    artifacts: {
      name: "Artifact |||| Artifacts",
      fields: {
        file_name: "File name",
        comment: "Comment",
        src: "File",
        created: "Created",
      },
    },
    submits: {
      name: "Submit |||| Submits",
      id_of: "Submit ID",
      fields: {
        account: "Account",
        candidate_id: "Candidate",
        candidate_snp: "Candidate SNP",
        candidate_type: "Candidate type",
        latest_event_created: "Latest event created at",
        created: "Created at",
        project_id: "Project",
        comment: "Comment",
        submit_date: "Date",
        status: "Status",
        candidate_artifact_id: "Resume",
      },
    },
    events: {
      name: "Event |||| Events",
      fields: {
        comment: "Comment",
        status: "Status",
        status_to: "New status",
        event_date: "Date",
      },
    },
  },
  staffinghrm: {
    vacancy: {
      statuses: {
        [VacancyStatus.OPEN]: "Open",
        [VacancyStatus.CLOSED]: "Closed",
        [VacancyStatus.IN_PROGRESS]: "In progress",
      },
      filters: {
        accounts: "Accounts",
        projects: "Projects",
        types: "Candidate types",
        qualifications: "Candidate qualifications",
        heads_of_vacancy: "Heads of vacation",
        users_ids: "HR-s",
        statuses: "Statuses",
      },
    },
    candidate: {
      show: {
        title: {
          info: "Info",
          artifacts: "Artifacts",
          submits: "Submits",
        },
      },
      filters: {
        snp: "SNP",
        type: "Type",
        qualification: "Qualification",
        created_after: "Created after",
        created_before: "Created before",
      },
    },
    artifacts: {
      dialog: {
        title: "Upload artifact",
      },
    },
    dashboard: {
      title: "Welcome to admin page!",
    },
    actions: {
      add: "Add",
      download: "Download",
      sign_in: "Sign in",
    },
    input: {
      dropzone_message_active: "Drop file here to upload",
    },
    messages: {
      profile_updated: "Your profile have been updated",
    },
    login: {
      email: "Email",
      password: "Password",
      enter_sso: "Login with SSO",
    },
    users: {
      my_profile: "My profile",
    },
    submits: {
      statuses: {
        [EventStatus.RESUME]: "Sended resume",
        [EventStatus.REJRES]: "Rejected resume",
        [EventStatus.INTERW]: "Appointed interview",
        [EventStatus.REFINT]: "Rejected by interview",
        [EventStatus.PASSED]: "Passed interview",
        [EventStatus.INPROJ]: "Invited to project",
        [EventStatus.ACCEPT]: "Offer accepted",
        [EventStatus.DECLIN]: "Offer declined",
        [EventStatus.PROJEC]: "Project entry",
        [EventStatus.FORCER]: "Vacancy on hold",
        [EventStatus.SELF_R]: "Self reject",
      },
      journal: "Journal",
      resume: "Attached resume",
      dialog: {
        title: "Create submit",
      },
      filters: {
        statuses: "Statuses",
      },
    },
    submit_events: {
      dialog: {
        title: "Add event",
      },
    },
    errors: {
      validation: {
        cyrillic_or_latin_only:
          "Field should contain only cyrillic or latin letters",
        user_doesnt_exists: "User doesnt exists",
        user_already_exists: "User with this email already exist",
        unknown_sort_order: "Sort order %{order} doesnt exist",
        string_type: "Field should contain non empty string",
        missing: "Field is required",
        int_type: "Field should contain a number",
      },
      notification: {
        unable_to_delete_artifact: "Unable to delete artifact",
        cant_delete_only_event: "Unable to delete only submit event",
        cant_delete_not_last_event: "You can delete only last submit event",
        unable_to_create: "Unable to create",
        unable_to_delete: "Unable to delete",
        unable_to_update: "Unable to update",
        artifact_belongs_to_other_user: "Artifact belongs to other candidate",
      },
    },
  },
};
