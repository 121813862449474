import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  Edit,
  ExportButton,
  FunctionField,
  IconButtonWithTooltip,
  List,
  Pagination,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  SaveButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useCreate,
  useNotify,
  useRefresh,
  useTranslate,
  WithRecord,
} from "react-admin";
import {
  Box,
  Button,
  DialogTitle,
  Dialog,
  Typography,
  Link,
} from "@mui/material";
import { useEffect, useState } from "react";
import { basePath } from "../providers/env";
import {
  apiPath,
  downloadFileAuthorized,
  httpClient,
} from "../providers/dataProvider";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import * as colors from "@mui/material/colors";
import { EventStatus, EventStatusColors } from "../dicts";
import { useParams } from "react-router";
import useSWR, { mutate } from "swr";

interface CreateSubmitModalProps {
  onToggle(val: boolean): void;
  opened: boolean;
  refresh: () => void;
}

export function getYYYYMMDD(date: string): string {
  const dateParsed = new Date(date);
  var mm = dateParsed.getMonth() + 1; // getMonth() is zero-based
  var dd = dateParsed.getDate();
  return `${dateParsed.getFullYear()}-${(mm > 9 ? "" : "0") + mm}-${
    (dd > 9 ? "" : "0") + dd
  }`;
}

const CreateSubmitModal = (props: CreateSubmitModalProps) => {
  const { id: candidateId } = useParams();
  const translate = useTranslate();
  const notify = useNotify();

  return (
    <Dialog fullWidth open={props.opened} onClose={() => props.onToggle(false)}>
      <DialogTitle>{translate("staffinghrm.submits.dialog.title")}</DialogTitle>
      <Create
        resource="submits"
        transform={(data) => ({
          ...data,
          candidate_id: candidateId,
          submit_date: getYYYYMMDD(data.submit_date),
        })}
        redirect={false}
        mutationOptions={{
          onSuccess: () => {
            props.onToggle(false);
            props.refresh();
            notify("ra.notification.created");
          },
        }}
      >
        <SimpleForm
          toolbar={
            <Toolbar>
              <SaveButton />
            </Toolbar>
          }
        >
          <ReferenceInput source="project_id" reference="project">
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput
            source="candidate_artifact_id"
            filter={{ candidate: candidateId }}
            reference="artifacts"
          />
          <TextInput source="comment" multiline />
          <DateInput defaultValue={new Date()} source="submit_date" />
        </SimpleForm>
      </Create>
    </Dialog>
  );
};

interface EditSubmitModalProps {
  onToggle(val: boolean): void;
  opened: boolean;
  refresh: () => void;
  submitId: any;
}

const EditSubmitModal = (props: EditSubmitModalProps) => {
  const { id: candidateId } = useParams();
  const translate = useTranslate();

  return (
    <Dialog fullWidth open={props.opened} onClose={() => props.onToggle(false)}>
      <DialogTitle>{translate("staffinghrm.submits.dialog.title")}</DialogTitle>
      <Edit
        resource="submits"
        id={props.submitId}
        redirect={false}
        mutationMode="pessimistic"
      >
        <SimpleForm
          toolbar={
            <Toolbar>
              <SaveButton onClick={() => props.onToggle(false)} />
            </Toolbar>
          }
        >
          <ReferenceInput
            source="candidate_artifact_id"
            filter={{ candidate: candidateId }}
            reference="artifacts"
            validate={[required()]}
          />
          <TextInput source="comment" multiline />
        </SimpleForm>
      </Edit>
    </Dialog>
  );
};

interface CreateSubmitEventModalProps {
  onToggle(val: boolean): void;
  opened: boolean;
  refresh: () => void;
  submitId: any;
}

export const CreateSubmitEventModal = (props: CreateSubmitEventModalProps) => {
  const notify = useNotify();
  const translate = useTranslate();
  const availableEventTypesKey = `${apiPath}/submits/${props.submitId}/available-event-types`;
  const { data: availableEventTypes, isLoading } = useSWR(
    props.submitId ? availableEventTypesKey : null,
    httpClient
  );
  useEffect(() => {
    if (props.opened && props.submitId) mutate(availableEventTypesKey);
  }, [props.opened]);

  return (
    <Dialog fullWidth open={props.opened} onClose={() => props.onToggle(false)}>
      <DialogTitle>
        {translate("staffinghrm.submit_events.dialog.title")}
      </DialogTitle>
      <Create
        resource="events"
        transform={(data) => ({
          ...data,
          submit_id: props.submitId,
          event_date: getYYYYMMDD(data.event_date),
        })}
        redirect={false}
        mutationOptions={{
          onSuccess: () => {
            props.onToggle(false);
            props.refresh();
            notify("ra.notification.created");
          },
        }}
      >
        <SimpleForm
          toolbar={
            <Toolbar>
              <SaveButton />
            </Toolbar>
          }
        >
          <AutocompleteInput
            source="status_to"
            loading={isLoading}
            choices={availableEventTypes?.json?.map((key: string) => ({
              id: key,
              name: translate(
                `staffinghrm.submits.statuses.${
                  EventStatus[key as keyof typeof EventStatus]
                }`
              ),
            }))}
          />
          <TextInput source="comment" multiline />
          <DateInput source="event_date" defaultValue={new Date()} />
        </SimpleForm>
      </Create>
    </Dialog>
  );
};

interface EditSubmitEventModalProps {
  onToggle(val: boolean): void;
  opened: boolean;
  refresh: () => void;
  submitEventId: any;
}

export const EditSubmitEventModal = (props: EditSubmitEventModalProps) => {
  const translate = useTranslate();

  return (
    <Dialog fullWidth open={props.opened} onClose={() => props.onToggle(false)}>
      <DialogTitle>
        {translate("staffinghrm.submit_events.dialog.title")}
      </DialogTitle>
      <Edit
        resource="events"
        mutationMode="pessimistic"
        id={props.submitEventId}
        redirect={false}
      >
        <SimpleForm
          toolbar={
            <Toolbar>
              <SaveButton onClick={() => props.onToggle(false)} />
            </Toolbar>
          }
        >
          <TextInput source="comment" multiline />
        </SimpleForm>
      </Edit>
    </Dialog>
  );
};

export interface CandidatesSubmitsExpandProps {
  setOpenedSubmitEvents: (val: boolean) => void;
  setSelectedSubmit: (val: any) => void;
  setOpenedEditSubmitEvents: (val: any) => void;
  setSelectedSubmitEvent: (val: any) => void;
}

export const CandidatesSubmitsExpand = ({
  setOpenedSubmitEvents,
  setSelectedSubmit,
  setOpenedEditSubmitEvents,
  setSelectedSubmitEvent,
}: CandidatesSubmitsExpandProps) => {
  const translate = useTranslate();

  return (
    <SimpleShowLayout>
      <WithRecord
        label="staffinghrm.submits.journal"
        render={(submitRecord) => (
          <>
            <TopToolbar>
              <Button
                variant={"contained"}
                onClick={() => {
                  setSelectedSubmit(submitRecord?.id);
                  setOpenedSubmitEvents(true);
                }}
              >
                {translate("staffinghrm.actions.add")}
              </Button>
            </TopToolbar>
            <ReferenceManyField
              reference="events"
              target="submit_id"
              pagination={<Pagination />}
              sort={{ field: "event_date", order: "ASC" }}
            >
              <Datagrid bulkActionButtons={false}>
                <WithRecord
                  render={(submitEventRecord) => (
                    <Typography
                      variant="button"
                      color={
                        colors[
                          EventStatusColors[
                            EventStatus[
                              submitEventRecord?.status_to as keyof typeof EventStatus
                            ]
                          ]
                        ][800]
                      }
                    >
                      {translate(
                        `staffinghrm.submits.statuses.${
                          EventStatus[
                            submitEventRecord?.status_to as keyof typeof EventStatus
                          ]
                        }`
                      )}
                    </Typography>
                  )}
                />
                <DateField source="event_date" />
                <TextField source="comment" />
                <DeleteButton redirect={false} mutationMode="pessimistic" />
                <WithRecord
                  render={(submitRecord) => (
                    <Box>
                      <IconButtonWithTooltip
                        label="ra.action.edit"
                        onClick={() => {
                          setSelectedSubmitEvent(submitRecord?.id);
                          setOpenedEditSubmitEvents(true);
                        }}
                      >
                        <EditIcon />
                      </IconButtonWithTooltip>
                    </Box>
                  )}
                />
              </Datagrid>
            </ReferenceManyField>
          </>
        )}
      />
      <TextField source="comment" label="resources.submits.fields.comment" />
      <ReferenceField reference="artifacts" source="candidate_artifact_id" />
    </SimpleShowLayout>
  );
};

export const CandidatesSubmitsList = () => {
  const { id: candidateId } = useParams();
  const [openedCreateSubmits, setOpenedCreateSubmits] = useState(false);
  const [openedEditSubmits, setOpenedEditSubmits] = useState(false);
  const [openedCreateSubmitEvents, setOpenedCreateSubmitEvents] =
    useState(false);
  const [openedEditSubmitEvents, setOpenedEditSubmitEvents] = useState(false);
  const [selectedSubmit, setSelectedSubmit] = useState(null);
  const [selectedSubmitEvent, setSelectedSubmitEvent] = useState(null);
  const translate = useTranslate();
  const refresh = useRefresh();

  return (
    <Box>
      <TopToolbar>
        <Button
          variant={"contained"}
          onClick={() => setOpenedCreateSubmits(true)}
        >
          {translate("staffinghrm.actions.add")}
        </Button>
      </TopToolbar>

      <List
        resource="submits"
        component={"div"}
        title=" "
        disableSyncWithLocation
        filter={{ candidate_id: candidateId }}
        sort={{ field: "submit_date", order: "ASC" }}
      >
        <Datagrid
          expand={
            <CandidatesSubmitsExpand
              setOpenedSubmitEvents={setOpenedCreateSubmitEvents}
              setSelectedSubmit={setSelectedSubmit}
              setOpenedEditSubmitEvents={setOpenedEditSubmitEvents}
              setSelectedSubmitEvent={setSelectedSubmitEvent}
            />
          }
        >
          <ReferenceField
            source="project_id"
            sortable={false}
            reference="project"
          >
            <TextField source="name" />
          </ReferenceField>
          <WithRecord
            label="resources.submits.fields.status"
            render={(submitRecord) => {
              return (
                <Typography
                  variant="button"
                  color={
                    colors[
                      EventStatusColors[
                        EventStatus[
                          submitRecord?.status as keyof typeof EventStatus
                        ]
                      ]
                    ][800]
                  }
                >
                  {translate(
                    `staffinghrm.submits.statuses.${
                      EventStatus[
                        submitRecord?.status as keyof typeof EventStatus
                      ]
                    }`
                  )}
                </Typography>
              );
            }}
          />
          <DateField source="submit_date" />
          <TextField source="comment" />
          <WithRecord
            render={(submitRecord) => (
              <Box>
                <IconButtonWithTooltip
                  label="staffinghrm.submit_events.dialog.title"
                  onClick={() => {
                    setSelectedSubmit(submitRecord?.id);
                    setOpenedCreateSubmitEvents(true);
                  }}
                >
                  <AddIcon />
                </IconButtonWithTooltip>
              </Box>
            )}
          />
          <WithRecord
            render={(submitRecord) => (
              <Box>
                <IconButtonWithTooltip
                  label="ra.action.edit"
                  onClick={() => {
                    setSelectedSubmit(submitRecord?.id);
                    setOpenedEditSubmits(true);
                  }}
                >
                  <EditIcon />
                </IconButtonWithTooltip>
              </Box>
            )}
          />
        </Datagrid>
      </List>

      <CreateSubmitModal
        opened={openedCreateSubmits}
        onToggle={setOpenedCreateSubmits}
        refresh={refresh}
      />
      <CreateSubmitEventModal
        opened={openedCreateSubmitEvents}
        onToggle={setOpenedCreateSubmitEvents}
        refresh={refresh}
        submitId={selectedSubmit}
      />
      <EditSubmitModal
        opened={openedEditSubmits}
        onToggle={setOpenedEditSubmits}
        refresh={refresh}
        submitId={selectedSubmit}
      />
      <EditSubmitEventModal
        opened={openedEditSubmitEvents}
        onToggle={setOpenedEditSubmitEvents}
        refresh={refresh}
        submitEventId={selectedSubmitEvent}
      />
    </Box>
  );
};
