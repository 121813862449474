import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import { useTranslate } from "react-admin";

const Component = () => {
  const translate = useTranslate();

  return (
    <Card>
      <CardHeader title={translate("staffinghrm.dashboard.title")} />
    </Card>
  );
};

export default Component;
