import {
  BooleanField,
  BooleanInput,
  Datagrid,
  FunctionField,
  Edit,
  EditButton,
  EmailField,
  List,
  SimpleForm,
  TextField,
  TextInput,
  usePermissions,
  PasswordInput,
} from "react-admin";
import { Divider, Typography } from "@mui/material";

export const UserList = (props: any) => {
  const { isLoading, permissions } = usePermissions();

  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" />
        <FunctionField
          source="full_name"
          render={(record) => `${record.first_name} ${record.last_name}`}
        />
        ;
        <EmailField source="email" />
        <BooleanField source="is_active" />
        <BooleanField source="is_superuser" />
        {!isLoading && permissions.is_superuser && <EditButton />}
      </Datagrid>
    </List>
  );
};

export const UserEdit = (props: any) => (
  <Edit
    {...props}
    mutationMode="pessimistic"
    title={
      <FunctionField
        render={(record: any) => (
          <Typography variant="h6">
            {`Пользователь ${record.last_name} ${record.first_name}`}
          </Typography>
        )}
      />
    }
  >
    <SimpleForm>
      <FunctionField
        label="ID"
        render={(record: any) => (
          <Typography variant="body1">ID пользователя - {record.id}</Typography>
        )}
      />
      <Divider sx={{ marginBottom: 2, marginTop: 1 }} />
      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <TextInput source="email" />
      <PasswordInput source="password" />
      <BooleanInput source="is_active" />
      <BooleanInput source="is_superuser" />
    </SimpleForm>
  </Edit>
);
