import {
  Create,
  Datagrid,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  DateField,
  DateInput,
  FormDataConsumer,
  SelectInput,
  useRecordContext,
  Edit,
  EditButton,
  WithRecord,
  FunctionField,
  useTranslate,
  useRefresh,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useListContext,
} from "react-admin";

import {
  Box,
  Button,
  DialogTitle,
  Dialog,
  Typography,
  Link,
  Divider,
  IconButton,
} from "@mui/material";
import {
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import * as colors from "@mui/material/colors";

import {
  CandidatesSubmitsExpand,
  CreateSubmitEventModal,
  EditSubmitEventModal,
  getYYYYMMDD,
} from "./CandidatesSubmits";
import { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import { EventStatus, EventStatusColors } from "../dicts";

const ArtifactInput = () => {
  const candidate_id = useWatch<{ candidate_id: string }>({
    name: "candidate_id",
  });
  const { setValue } = useFormContext();
  useEffect(() => {
    if (!candidate_id) setValue("candidate_artifact_id", []);
  }, [candidate_id]);

  return candidate_id ? (
    <ReferenceInput
      source="candidate_artifact_id"
      filter={{ candidate: candidate_id }}
      reference="artifacts"
    />
  ) : (
    <SelectInput source="candidate_artifact_id" choices={[]} value={[]} />
  );
};

export const SubmitCreate = (props: any) => (
  <Create
    {...props}
    transform={(data) => ({
      ...data,
      submit_date: getYYYYMMDD(data.submit_date),
    })}
  >
    <SimpleForm>
      <ReferenceInput source="project_id" reference="project" />
      <ReferenceInput reference="candidate" source="candidate_id" />
      <ArtifactInput />
      <TextInput
        source="comment"
        multiline
        defaultValue={""}
        parse={(val) => val || ""}
      />
      <DateInput defaultValue={new Date()} source="submit_date" />
    </SimpleForm>
  </Create>
);

export const SubmitEdit = (props: any) => {
  const translate = useTranslate();

  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      title={
        <FunctionField
          render={(record: any) => (
            <Typography variant="h6">{`${translate("resources.submits.name", {
              smart_count: 1,
            })} ${record.id}`}</Typography>
          )}
        />
      }
    >
      <SimpleForm>
        <FunctionField
          label="ID"
          render={(record: any) => (
            <Typography variant="body1">
              {`${translate("resources.submits.id_of")} - ${record.id}`}
            </Typography>
          )}
        />
        <Divider sx={{ marginBottom: 2, marginTop: 1 }} />
        <WithRecord
          render={(record) => (
            <ReferenceInput
              source="candidate_artifact_id"
              filter={{ candidate: record?.candidate_id }}
              reference="artifacts"
            />
          )}
        />
        <TextInput source="comment" multiline />
      </SimpleForm>
    </Edit>
  );
};

const ResetFilterButton = ({ alwaysOn }) => {
  const { setFilters } = useListContext();
  return (
    <IconButton size="large" onClick={() => setFilters([], {}, false)}>
      <Close />
    </IconButton>
  );
};

const submitFilters = [
  <ReferenceArrayInput source="accounts" reference="account" alwaysOn>
    <AutocompleteArrayInput label="staffinghrm.vacancy.filters.accounts" />
  </ReferenceArrayInput>,
  <ReferenceArrayInput source="projects" reference="project" alwaysOn>
    <AutocompleteArrayInput label="staffinghrm.vacancy.filters.projects" />
  </ReferenceArrayInput>,
  <ReferenceArrayInput source="types" reference="candidate-types" alwaysOn>
    <AutocompleteArrayInput label="staffinghrm.vacancy.filters.types" />
  </ReferenceArrayInput>,
  <AutocompleteArrayInput
    source="statuses"
    alwaysOn
    label="staffinghrm.submits.filters.statuses"
    choices={Object.keys(EventStatus).map((val) => {
      return {
        id: val,
        name: `staffinghrm.submits.statuses.${
          EventStatus[val as keyof typeof EventStatus]
        }`,
      };
    })}
  />,
  <ResetFilterButton alwaysOn />,
];

export const SubmitList = (props: any) => {
  const [openedCreateSubmitEvents, setOpenedCreateSubmitEvents] =
    useState(false);
  const [openedEditSubmitEvents, setOpenedEditSubmitEvents] = useState(false);
  const [selectedSubmit, setSelectedSubmit] = useState(null);
  const [selectedSubmitEvent, setSelectedSubmitEvent] = useState(null);
  const refresh = useRefresh();
  const translate = useTranslate();

  return (
    <>
      <List {...props} filters={submitFilters}>
        <Datagrid
          expand={
            <CandidatesSubmitsExpand
              setOpenedSubmitEvents={setOpenedCreateSubmitEvents}
              setSelectedSubmit={setSelectedSubmit}
              setOpenedEditSubmitEvents={setOpenedEditSubmitEvents}
              setSelectedSubmitEvent={setSelectedSubmitEvent}
            />
          }
        >
          <ReferenceField
            label="resources.submits.fields.account"
            reference="project"
            source="project_id"
            sortBy="project__account__name"
          >
            <ReferenceField reference="account" source="account_id" />
          </ReferenceField>
          <ReferenceField
            reference="project"
            source="project_id"
            sortBy="project__name"
          />
          <ReferenceField
            label="resources.submits.fields.candidate_snp"
            reference="candidate"
            source="candidate_id"
            sortBy="candidate__surname"
            link="show"
          />
          <ReferenceField
            label="resources.submits.fields.candidate_type"
            reference="candidate"
            source="candidate_id"
            sortBy="candidate__type__name"
          >
            <ReferenceField reference="candidate-types" source="type_id" />
          </ReferenceField>
          <WithRecord
            label="resources.submits.fields.status"
            render={(submitRecord) => {
              return (
                <Typography
                  variant="button"
                  color={
                    colors[
                      EventStatusColors[
                        EventStatus[
                          submitRecord?.status as keyof typeof EventStatus
                        ]
                      ]
                    ][800]
                  }
                >
                  {translate(
                    `staffinghrm.submits.statuses.${
                      EventStatus[
                        submitRecord?.status as keyof typeof EventStatus
                      ]
                    }`
                  )}
                </Typography>
              );
            }}
          />
          <DateField source="created" />
          <DateField
            source="latest_event_created"
            sortBy="latest_submit_event__created"
          />
          <ReferenceField
            reference="artifacts"
            source="candidate_artifact_id"
            sortBy="candidate_artifact__file__file_name"
          />
          <TextField source="comment" />
          <EditButton />
        </Datagrid>
      </List>
      <CreateSubmitEventModal
        opened={openedCreateSubmitEvents}
        onToggle={setOpenedCreateSubmitEvents}
        refresh={refresh}
        submitId={selectedSubmit}
      />
      <EditSubmitEventModal
        opened={openedEditSubmitEvents}
        onToggle={setOpenedEditSubmitEvents}
        refresh={refresh}
        submitEventId={selectedSubmitEvent}
      />
    </>
  );
};
