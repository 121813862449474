import polyglotI18nProvider from "ra-i18n-polyglot";
import { resolveBrowserLocale } from "react-admin";
import { ru } from "./i18n/ru";
import { en } from "./i18n/en";

const translations: any = { en, ru };

export const i18nProvider = polyglotI18nProvider(
  (locale) => (translations[locale] ? translations[locale] : translations.en),
  resolveBrowserLocale(),
  [
    { locale: "en", name: "English" },
    { locale: "ru", name: "Русский" },
  ]
);
