import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  SelectInput,
  ReferenceInput,
  NumberField,
  SimpleShowLayout,
  FunctionField,
  NumberInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ArrayField,
  WithListContext,
  useCreate,
  useRedirect,
  useRecordContext,
  useNotify,
  useListContext,
  useTranslate,
  ArrayInput,
  SelectArrayInput,
} from "react-admin";

import { Stack, Chip, Box, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Divider, Typography } from "@mui/material";
import { VacancyStatus } from "../dicts";

const VacancyExpandPanel = () => {
  const translate = useTranslate();

  return (
    // @ts-ignore
    <SimpleShowLayout>
      <ReferenceField reference="account" source="account_id" />
      <ReferenceField reference="project" source="project_id" />
      <ReferenceField reference="candidate-types" source="candidate_type_id" />
      <ReferenceField
        reference="candidate-qualifications"
        source="candidate_qualification_id"
      />
      <NumberField source="quantity" />
      <TextField source="salary_fork" />
      <FunctionField
        source="head_of_vacancy.full_name"
        render={(record: any) =>
          `${record.head_of_vacancy.first_name} ${record.head_of_vacancy.last_name}`
        }
      />
      <FunctionField
        source="status"
        render={(record: any) =>
          translate(
            `staffinghrm.vacancy.statuses.${
              VacancyStatus[record.status as keyof typeof VacancyStatus]
            }`
          )
        }
      />
      <ArrayField source="user_ids">
        <WithListContext
          render={({ isLoading, data }) =>
            !isLoading && (
              <Stack direction="row" spacing={1}>
                {data.map((user) => (
                  <Chip
                    key={user.id}
                    label={`${user.first_name} ${user.last_name}`}
                  />
                ))}
              </Stack>
            )
          }
        />
      </ArrayField>
      <TextField source="description" component="pre" />
      <FunctionField
        source="created_by.full_name"
        render={(record: any) =>
          `${record.created_by.first_name} ${record.created_by.last_name}`
        }
      />
      <TextField source="comment" component="pre" />
    </SimpleShowLayout>
  );
};

const shortenedStyle = {
  maxWidth: "100px",
  display: "inline-block",
  overflow: "hidden",
  textOverflow: "ellipsis",
  "text-wrap": "nowrap",
};

const ResetFilterButton = ({ alwaysOn }) => {
  const { setFilters } = useListContext();
  return (
    <IconButton size="large" onClick={() => setFilters([], {}, false)}>
      <Close />
    </IconButton>
  );
};

const vacancyFilters = [
  <ReferenceArrayInput source="accounts" reference="account" alwaysOn>
    <AutocompleteArrayInput label="staffinghrm.vacancy.filters.accounts" />
  </ReferenceArrayInput>,
  <ReferenceArrayInput source="projects" reference="project" alwaysOn>
    <AutocompleteArrayInput label="staffinghrm.vacancy.filters.projects" />
  </ReferenceArrayInput>,
  <ReferenceArrayInput source="types" reference="candidate-types" alwaysOn>
    <AutocompleteArrayInput label="staffinghrm.vacancy.filters.types" />
  </ReferenceArrayInput>,
  <ReferenceArrayInput
    source="qualifications"
    reference="candidate-qualifications"
    alwaysOn
  >
    <AutocompleteArrayInput label="staffinghrm.vacancy.filters.qualifications" />
  </ReferenceArrayInput>,
  <ReferenceArrayInput
    source="heads_of_vacancy"
    reference="users"
    label="resources.users.name"
    alwaysOn
  >
    <AutocompleteArrayInput label="staffinghrm.vacancy.filters.heads_of_vacancy" />
  </ReferenceArrayInput>,
  <AutocompleteArrayInput
    source="statuses"
    alwaysOn
    label="staffinghrm.vacancy.filters.statuses"
    choices={Object.values(VacancyStatus).map((val) => {
      return {
        id: val,
        name: `staffinghrm.vacancy.statuses.${VacancyStatus[val]}`,
      };
    })}
  />,
  <ReferenceArrayInput source="users_ids" reference="users" alwaysOn>
    <AutocompleteArrayInput label="staffinghrm.vacancy.filters.users_ids" />
  </ReferenceArrayInput>,
  <ResetFilterButton alwaysOn />,
];

export const VacancyList = (props: any) => {
  const translate = useTranslate();

  return (
    <List {...props} filters={vacancyFilters}>
      <Datagrid expand={<VacancyExpandPanel />}>
        <ReferenceField
          reference="account"
          source="account_id"
          sortBy="account__name"
        />
        <ReferenceField
          reference="project"
          source="project_id"
          sortBy="project__name"
        />
        <ReferenceField
          reference="candidate-types"
          source="candidate_type_id"
          sortBy="candidate_type__name"
        />
        <ReferenceField
          reference="candidate-qualifications"
          source="candidate_qualification_id"
          sortBy="candidate_qualification__name"
        />
        <NumberField source="quantity" />
        <TextField source="salary_fork" />
        <FunctionField
          source="head_of_vacancy.full_name"
          render={(record: any) =>
            `${record.head_of_vacancy.first_name} ${record.head_of_vacancy.last_name}`
          }
        />
        <FunctionField
          source="status"
          render={(record: any) =>
            translate(
              `staffinghrm.vacancy.statuses.${
                VacancyStatus[record.status as keyof typeof VacancyStatus]
              }`
            )
          }
        />
        <ArrayField source="user_ids" sortable={false}>
          <WithListContext
            render={({ isLoading, data }) =>
              !isLoading && (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
                  {data.map((user) => (
                    <Chip
                      key={user.id}
                      label={`${user.first_name} ${user.last_name}`}
                    />
                  ))}
                </Box>
              )
            }
          />
        </ArrayField>
        <TextField source="description" sx={shortenedStyle} />
        <FunctionField
          source="created_by.full_name"
          render={(record: any) =>
            `${record.created_by.first_name} ${record.created_by.last_name}`
          }
        />
        <TextField source="comment" sx={shortenedStyle} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const VacancyEditForm = (props: any) => {
  const translate = useTranslate();
  const record: any = useRecordContext();

  const {
    user_ids = [],
    head_of_vacancy = null,
    ...transformedRecord
  } = { ...record, head_of_vacancy_id: record?.head_of_vacancy?.id };
  transformedRecord["user_ids"] = user_ids.map((item: any) => item.id);

  return (
    <SimpleForm record={transformedRecord} sanitizeEmptyValues={false}>
      <FunctionField
        label="ID"
        render={(record: any) => (
          <Typography variant="body1">ID вакансии - {record.id}</Typography>
        )}
      />
      <Divider sx={{ marginBottom: 2, marginTop: 1 }} />
      <ReferenceInput reference="account" source="account_id" />
      <ReferenceInput reference="project" source="project_id" />
      <ReferenceInput reference="candidate-types" source="candidate_type_id" />
      <ReferenceInput
        reference="candidate-qualifications"
        source="candidate_qualification_id"
      />
      <NumberInput source="quantity" />
      <TextInput source="salary_fork" parse={(val) => val} defaultValue="" />
      <ReferenceInput reference="users" source="head_of_vacancy_id" />
      <SelectInput
        source="status"
        choices={Object.values(VacancyStatus).map((val) => {
          return {
            id: val,
            name: translate(
              `staffinghrm.vacancy.statuses.${VacancyStatus[val]}`
            ),
          };
        })}
      />
      <ReferenceArrayInput reference="users" source="user_ids" />
      <TextInput
        multiline
        source="description"
        parse={(val) => val}
        defaultValue=""
      />
      <TextInput
        multiline
        source="comment"
        parse={(val) => val}
        defaultValue=""
      />
    </SimpleForm>
  );
};

export const VacancyEdit = (props: any) => {
  return (
    <Edit
      mutationMode="pessimistic"
      title={
        <ReferenceField reference="project" source="project_id" link={false}>
          <FunctionField
            render={(record: any) => (
              <Typography variant="h6">
                Вакансия на проект {record.name}
              </Typography>
            )}
          />
        </ReferenceField>
      }
    >
      <VacancyEditForm />
    </Edit>
  );
};

export const VacancyCreate = (props: any) => {
  const translate = useTranslate();

  return (
    <Create {...props}>
      <SimpleForm sanitizeEmptyValues={false}>
        <ReferenceInput reference="account" source="account_id" />
        <ReferenceInput reference="project" source="project_id" />
        <ReferenceInput
          reference="candidate-types"
          source="candidate_type_id"
        />
        <ReferenceInput
          reference="candidate-qualifications"
          source="candidate_qualification_id"
        />
        <NumberInput source="quantity" />
        <TextInput source="salary_fork" parse={(val) => val} defaultValue="" />
        <ReferenceInput reference="users" source="head_of_vacancy_id" />
        <SelectInput
          source="status"
          choices={Object.values(VacancyStatus).map((val) => {
            return {
              id: val,
              name: translate(
                `staffinghrm.vacancy.statuses.${VacancyStatus[val]}`
              ),
            };
          })}
        />
        <ReferenceArrayInput reference="users" source="user_ids" />
        <TextInput
          multiline
          source="description"
          parse={(val) => val}
          defaultValue=""
        />
        <TextInput
          multiline
          source="comment"
          parse={(val) => val}
          defaultValue=""
        />
      </SimpleForm>
    </Create>
  );
};
