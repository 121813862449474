// in src/i18n/en.js
import russianMessages from "ra-language-russian";
import { EventStatus, VacancyStatus } from "../../dicts";

// Плюрализация для русского:
// %{smart_count} бургер |||| %{smart_count} бургера |||| %{smart_count} бургеров

// Прим:
// 1 бургер
// 2 бургера
// 5 бургеров

// %{smart_count} не обязателен, это число

export const ru = {
  ...russianMessages,
  resources: {
    users: {
      name: "Пользователь |||| Пользователи |||| пользователей",
      fields: {
        id: "Id",
        first_name: "Имя",
        last_name: "Фамилия",
        full_name: "Имя Фамилия",
        email: "Email",
        is_active: "Активен",
        is_superuser: "Суперпользователь",
        password: "Пароль",
      },
    },
    "candidate-qualifications": {
      name: "Квалицикация кандидата |||| Квалификации кандидата |||| квалификаций кандидатов",
      fields: {
        id: "Id",
        name: "Название",
      },
    },
    "candidate-types": {
      name: "Тип кандидата |||| Типы кандидата |||| типов кандидата",
      fields: {
        id: "Id",
        name: "Название",
      },
    },
    account: {
      name: "Аккаунт |||| Аккаунты |||| аккаунтов",
      fields: {
        id: "Id",
        name: "Название",
      },
    },
    project: {
      name: "Проект |||| Проекты |||| проектов",
      fields: {
        id: "Id",
        name: "Название",
        description: "Описание",
        account_id: "Аккаунт",
      },
    },
    candidate: {
      name: "Кандидат |||| Кандидаты |||| кандидатов",
      fields: {
        id: "Id",
        surname: "Фамилия",
        name: "Имя",
        patronymic: "Отчество",
        type_id: "Тип",
        qualification_id: "Квалификация",
        expectation: "Ожидание",
        created: "Дата создания",
      },
    },
    vacancy: {
      name: "Вакансия |||| Вакансии |||| вакансий",
      fields: {
        account_id: "Аккаунт",
        project_id: "Проект",
        candidate_type_id: "Тип кандидата",
        candidate_qualification_id: "Квалификация кандидата",
        quantity: "Количество",
        salary_fork: "Вилка ожиданий по ЗП",
        "head_of_vacancy.full_name": "Ответственный за вакансию",
        "head_of_vacancy.id": "Ответственный за вакансию",
        head_of_vacancy_id: "Ответственный за вакансию",
        expectation: "Ожидание",
        comment: "Комментарий",
        user_ids: "HR-ы",
        description: "Описание",
        "created_by.full_name": "Создатель",
        status: "Статус",
      },
    },
    artifacts: {
      name: "Артефакт |||| Артефакты |||| артефактов",
      fields: {
        file_name: "Имя файла",
        comment: "Комментарий",
        src: "Файл",
        created: "Дата загрузки",
      },
    },
    submits: {
      name: "Активность |||| Активности |||| активностей",
      id_of: "ID активности",
      fields: {
        account: "Аккаунт",
        candidate_id: "Кандидат",
        candidate_snp: "ФИО кандидата",
        candidate_type: "Тип кандидата",
        latest_event_created: "Дата изменения последнего статуса",
        created: "Дата первоначальной отправки",
        project_id: "Проект",
        comment: "Комментарий",
        submit_date: "Дата",
        status: "Статус",
        candidate_artifact_id: "Резюме",
      },
    },
    events: {
      name: "Событие |||| События |||| событий",
      fields: {
        comment: "Комментарий",
        status: "Статус",
        status_to: "Новый статус",
        event_date: "Дата",
      },
    },
  },
  staffinghrm: {
    vacancy: {
      statuses: {
        [VacancyStatus.OPEN]: "Открыта",
        [VacancyStatus.CLOSED]: "Закрыта",
        [VacancyStatus.IN_PROGRESS]: "Выполняется",
      },
      filters: {
        accounts: "Аккаунты",
        projects: "Проекты",
        types: "Типы кандидата",
        qualifications: "Квалификации кандидата",
        heads_of_vacancy: "Ответственные за вакансию",
        users_ids: "HR-ы",
        statuses: "Статусы",
      },
    },
    candidate: {
      show: {
        title: {
          info: "Информация",
          artifacts: "Артефакты",
          submits: "Активности",
        },
      },
      filters: {
        snp: "ФИО",
        type: "Тип",
        qualification: "Квалификация",
        created_after: "Создан после",
        created_before: "Создан до",
      },
    },
    artifacts: {
      dialog: {
        title: "Загрузить артефакт",
      },
    },
    dashboard: {
      title: "Добро пожаловать в админку!",
    },
    actions: {
      add: "Добавить",
      download: "Скачать",
      sign_in: "Войти",
    },
    input: {
      dropzone_message_active: "Перетащите файл для загрузки",
    },
    messages: {
      profile_updated: "Ваш профиль был обновлён",
    },
    login: {
      email: "Email",
      password: "Пароль",
      enter_sso: "Войти через SSO",
    },
    users: {
      my_profile: "Мой профиль",
    },
    submits: {
      statuses: {
        [EventStatus.RESUME]: "Отправлено резюме",
        [EventStatus.REJRES]: "Отказ по резюме",
        [EventStatus.INTERW]: "Назначено собеседование",
        [EventStatus.REFINT]: "Отказ по собеседованию",
        [EventStatus.PASSED]: "Прошел собеседование",
        [EventStatus.INPROJ]: "Приглашён на проект",
        [EventStatus.ACCEPT]: "Оффер принят",
        [EventStatus.DECLIN]: "Отказ от оффера",
        [EventStatus.PROJEC]: "Выход на проект",
        [EventStatus.FORCER]: "Набор остановлен",
        [EventStatus.SELF_R]: "Кандидат не актуален",
      },
      journal: "Журнал",
      resume: "Прикреплённое резюме",
      dialog: {
        title: "Создать активность",
      },
      filters: {
        statuses: "Статусы",
      },
    },
    submit_events: {
      dialog: {
        title: "Добавить событие",
      },
    },
    errors: {
      validation: {
        cyrillic_or_latin_only:
          "Поле должно содержать только русские или латинские символы",
        user_doesnt_exists: "Пользователь не существует",
        user_already_exists: "Пользователь с таким email уже существует",
        unknown_sort_order: "Сортировки вида %{order} не существует",
        string_type: "В поле должна быть непустая строка",
        missing: "Поле обязательно",
        int_type: "В поле должно быть число",
      },
      notification: {
        unable_to_delete_artifact: "Невозможно удалить артефакт",
        cant_delete_only_event: "Невозможно удалить единственное событие",
        cant_delete_not_last_event: "Можно удалить только последнее событие",
        unable_to_create: "Невозможно создать объект",
        unable_to_delete: "Невозможно удалить объект",
        unable_to_update: "Невозможно обновить объект",
        artifact_belongs_to_other_user:
          "Артефакт пренадлежит другому кандидату",
      },
    },
  },
};
