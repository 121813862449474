import { CreateParams, fetchUtils, Options, UpdateParams } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import { basePath } from "./env";
import { stringify } from "query-string";

export const httpClient = (url: string, options: Options = {}) => {
  options.user = {
    authenticated: true,
    token: `Bearer ${localStorage.getItem("token")}`,
  };
  if (url.includes("/users/") && options.method === "PUT") {
    // We use PATCH for update on the backend for users, since PATCH is selective PUT, this change should be fine
    options.method = "PATCH";
  }
  return fetchUtils.fetchJson(url, options);
};

function getFileName(disposition: string): string {
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
  const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

  let fileName: string | null = null;
  if (utf8FilenameRegex.test(disposition)) {
    fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)![1]);
  } else {
    // prevent ReDos attacks by anchoring the ascii regex to string start and
    //  slicing off everything before 'filename='
    const filenameStart = disposition.toLowerCase().indexOf("filename=");
    if (filenameStart >= 0) {
      const partialDisposition = disposition.slice(filenameStart);
      const matches = asciiFilenameRegex.exec(partialDisposition);
      if (matches != null && matches[2]) {
        fileName = matches[2];
      }
    }
  }
  return fileName || "unknown";
}

export const downloadFileAuthorized = (url: string) => {
  let filename = "";
  fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => {
      filename = getFileName(response.headers.get("Content-Disposition") || "");
      return response.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
};

export const apiPath = `${basePath}/api/v1`;
const baseDataProvider = simpleRestProvider(apiPath, httpClient);

const transformFilterParams = (object: any) => {
  let initialAccumulator: any = {};

  return Object.entries(object).reduce((accumulator, val) => {
    accumulator[val[0]] = Array.isArray(val[1])
      ? val[1].map((obj) => `${obj}`).join()
      : `${val[1]}`;
    return accumulator;
  }, initialAccumulator);
};

export const dataProvider = {
  ...baseDataProvider,
  create: (resource: string, params: any) => {
    if (params.data instanceof FormData) {
      return httpClient(`${apiPath}/${resource}`, {
        method: "POST",
        body: params.data,
      }).then(({ json }) => ({ data: json }));
    } else return baseDataProvider.create(resource, params);
  },
  getList: (resource: string, params: any) => {
    if (resource === "candidate") {
      if (params?.filter?.start_date) {
        params.filter.start_date = new Date(
          Date.parse(params.filter.start_date)
        ).toISOString();
      }
      if (params?.filter?.final_date) {
        params.filter.final_date = new Date(
          Date.parse(params.filter.final_date) + 1 * 24 * 60 * 60 * 1000
        ).toISOString();
      }
    }

    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([rangeStart, rangeEnd]),
      ...transformFilterParams(params.filter),
    };
    const apiUrl = `${basePath}/api/v1`;
    const countHeader = "Content-Range";
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const options =
      countHeader === "Content-Range"
        ? {
            // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
            headers: new Headers({
              Range: `${resource}=${rangeStart}-${rangeEnd}`,
            }),
          }
        : {};

    return httpClient(url, options).then(({ headers, json }) => {
      if (!headers.has(countHeader)) {
        throw new Error(
          `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
        );
      }
      return {
        data: json,
        total:
          countHeader === "Content-Range"
            ? parseInt(headers.get("content-range").split("/").pop(), 10)
            : parseInt(headers.get(countHeader.toLowerCase())),
      };
    });
  },
  getMany: (resource: string, params: any) => {
    const query = {
      ...transformFilterParams({ id: params.ids }),
    };
    const apiUrl = `${basePath}/api/v1`;
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },
  getManyReference: (resource: string, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      ...transformFilterParams({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const apiUrl = `${basePath}/api/v1`;
    const countHeader = "Content-Range";
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const options =
      countHeader === "Content-Range"
        ? {
            // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
            headers: new Headers({
              Range: `${resource}=${rangeStart}-${rangeEnd}`,
            }),
          }
        : {};

    return httpClient(url, options).then(({ headers, json }) => {
      if (!headers.has(countHeader)) {
        throw new Error(
          `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
        );
      }
      return {
        data: json,
        total:
          countHeader === "Content-Range"
            ? parseInt(headers.get("content-range").split("/").pop(), 10)
            : parseInt(headers.get(countHeader.toLowerCase())),
      };
    });
  },
};
