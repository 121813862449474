import {
  AutocompleteArrayInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  Labeled,
  List,
  NumberField,
  NumberInput,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  FunctionField,
  TextInput,
  useTranslate,
  useListContext,
  AutocompleteInput,
} from "react-admin";
import {
  Typography,
  Box,
  Divider,
  Grid,
  IconButton,
  Card,
} from "@mui/material";
import { CandidatesArtifactList } from "./CandidatesArtifacts";
import { Close } from "@mui/icons-material";
import { CandidatesSubmitsList } from "./CandidatesSubmits";

export const CandidatesShow = (props: any) => {
  const translate = useTranslate();

  return (
    <Card sx={{ mt: 3, p: 5 }}>
      <Show {...props} component={"div"}>
        <SimpleShowLayout>
          <Typography variant="h5">
            {translate("staffinghrm.candidate.show.title.info")}
          </Typography>
          <Box sx={{ maxWidth: "400px" }}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Labeled>
                  <TextField source="surname" />
                </Labeled>
              </Grid>
              <Grid item xs={4}>
                <Labeled>
                  <TextField source="name" />
                </Labeled>
              </Grid>
              <Grid item xs={4}>
                <Labeled>
                  <TextField source="patronymic" />
                </Labeled>
              </Grid>
              <Grid item xs={4}>
                <Labeled>
                  <ReferenceField
                    reference="candidate-types"
                    source="type_id"
                    link=""
                  >
                    <TextField source="name" />
                  </ReferenceField>
                </Labeled>
              </Grid>
              <Grid item xs={4}>
                <Labeled>
                  <ReferenceField
                    reference="candidate-qualifications"
                    source="qualification_id"
                    link=""
                  >
                    <TextField source="name" />
                  </ReferenceField>
                </Labeled>
              </Grid>
              <Grid item xs={4}>
                <Labeled>
                  <NumberField source="expectation" />
                </Labeled>
              </Grid>
            </Grid>
          </Box>
        </SimpleShowLayout>
      </Show>
      <Divider />
      <Box sx={{ "&": { display: "flex", gap: "8px" } }}>
        <Box sx={{ flexGrow: 1, flexBasis: 0 }}>
          <Typography variant="h6">
            {translate("staffinghrm.candidate.show.title.artifacts")}
          </Typography>
          <CandidatesArtifactList />
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box sx={{ flexGrow: 1, flexBasis: 0 }}>
          <Typography variant="h6">
            {translate("staffinghrm.candidate.show.title.submits")}
          </Typography>
          <CandidatesSubmitsList />
        </Box>
      </Box>
    </Card>
  );
};

const ResetFilterButton = () => {
  const { setFilters } = useListContext();
  return (
    <IconButton size="large" onClick={() => setFilters([], {}, false)}>
      <Close />
    </IconButton>
  );
};

const candidatesFilters = [
  <TextInput source="q" label="staffinghrm.candidate.filters.snp" alwaysOn />,
  <ReferenceArrayInput source="types" reference="candidate-types" alwaysOn>
    <AutocompleteArrayInput
      optionText="name"
      label="staffinghrm.candidate.filters.type"
    />
  </ReferenceArrayInput>,
  <ReferenceArrayInput
    source="qualifications"
    reference="candidate-qualifications"
    alwaysOn
  >
    <AutocompleteArrayInput
      optionText="name"
      label="staffinghrm.candidate.filters.qualification"
    />
  </ReferenceArrayInput>,
  <DateInput
    source="start_date"
    label="staffinghrm.candidate.filters.created_after"
    alwaysOn
  />,
  <DateInput
    source="final_date"
    label="staffinghrm.candidate.filters.created_before"
    alwaysOn
  />,
  <ResetFilterButton alwaysOn />,
];

export const CandidatesList = (props: any) => (
  <List
    {...props}
    filters={candidatesFilters}
    sort={{ field: "created", order: "ASC" }}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="surname" />
      <TextField source="name" />
      <TextField source="patronymic" />
      <ReferenceField
        reference="candidate-types"
        source="type_id"
        sortBy="type__name"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        reference="candidate-qualifications"
        source="qualification_id"
        sortBy="qualification__name"
      >
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="expectation" />
      <DateField source="created" />
      <EditButton />
    </Datagrid>
  </List>
);

export const CandidatesEdit = (props: any) => (
  <Edit
    {...props}
    mutationMode="pessimistic"
    title={
      <FunctionField
        render={(record: any) => (
          <Typography variant="h6">
            {`Кандидат ${record.surname} ${record.name} ${record.patronymic}`}
          </Typography>
        )}
      />
    }
  >
    <SimpleForm>
      <FunctionField
        label="ID"
        render={(record: any) => (
          <Typography variant="body1">ID кандидата - {record.id}</Typography>
        )}
      />
      <Divider sx={{ marginBottom: 2, marginTop: 1 }} />
      <TextInput source="surname" />
      <TextInput source="name" />
      <TextInput source="patronymic" />
      <ReferenceInput source="type_id" reference="candidate-types">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="qualification_id"
        reference="candidate-qualifications"
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="expectation" />
    </SimpleForm>
  </Edit>
);

export const CandidatesCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="surname" />
      <TextInput source="name" />
      <TextInput source="patronymic" />
      <ReferenceInput source="type_id" reference="candidate-types">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="qualification_id"
        reference="candidate-qualifications"
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="expectation" />
    </SimpleForm>
  </Create>
);
