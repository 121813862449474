import { createBrowserHistory as createHistory } from "history";
import { Admin, Resource, CustomRoutes } from "react-admin";
import { Route } from "react-router";
import MyLayout from "./components/AdminLayout";
import Dashboard from "./pages/Dashboard";
import {
  CandidatesCreate,
  CandidatesEdit,
  CandidatesList,
  CandidatesShow,
} from "./pages/Candidates";
import { ProfileEdit } from "./pages/ProfileEdit";
import { UserEdit, UserList } from "./pages/Users";
import PostIcon from "@mui/icons-material/PostAdd";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import AppsIcon from "@mui/icons-material/Apps";
import ArticleIcon from "@mui/icons-material/Article";
import {
  CandidatesTypesCreate,
  CandidatesTypesEdit,
  CandidatesTypesList,
} from "./pages/CandidateTypes";
import {
  CandidatesQualificationCreate,
  CandidatesQualificationEdit,
  CandidatesQualificationList,
} from "./pages/CandidateQualification";
import { AccountCreate, AccountEdit, AccountList } from "./pages/Accounts";
import { ProjectCreate, ProjectEdit, ProjectList } from "./pages/Projects";
import { VacancyCreate, VacancyEdit, VacancyList } from "./pages/Vacancy";
import LoginPage from "./pages/Login";

import { dataProvider } from "./providers/dataProvider";
import { i18nProvider } from "./providers/i18nProvider";
import authProvider from "./providers/authProvider";
import { Send as SendIcon } from "@mui/icons-material";
import { SubmitCreate, SubmitEdit, SubmitList } from "./pages/Submits";

const App = () => {
  return (
    <Admin
      disableTelemetry
      i18nProvider={i18nProvider}
      dataProvider={dataProvider}
      authProvider={authProvider}
      history={createHistory()}
      loginPage={LoginPage}
      layout={MyLayout}
      dashboard={Dashboard}
    >
      <CustomRoutes>
        <Route path="/my-profile" element={<ProfileEdit />} />
      </CustomRoutes>
      {(permissions) => [
        permissions.is_superuser === true
          ? [
              <Resource
                name="users"
                list={UserList}
                edit={UserEdit}
                icon={PersonIcon}
                recordRepresentation={(record) =>
                  `${record.last_name} ${record.first_name}`
                }
              />,
              <Resource
                name="candidate-qualifications"
                list={CandidatesQualificationList}
                edit={CandidatesQualificationEdit}
                create={CandidatesQualificationCreate}
                icon={PersonIcon}
                recordRepresentation={(record) => `${record.name}`}
              />,
              <Resource
                name="candidate-types"
                list={CandidatesTypesList}
                edit={CandidatesTypesEdit}
                create={CandidatesTypesCreate}
                icon={PersonIcon}
                recordRepresentation={(record) => `${record.name}`}
              />,
              <Resource
                name="account"
                list={AccountList}
                edit={AccountEdit}
                create={AccountCreate}
                icon={BusinessIcon}
                recordRepresentation={(record) => `${record.name}`}
              />,
              <Resource
                name="project"
                list={ProjectList}
                edit={ProjectEdit}
                create={ProjectCreate}
                icon={AppsIcon}
                recordRepresentation={(record) => `${record.name}`}
              />,
            ]
          : [
              <Resource
                name="users"
                list={UserList}
                icon={PersonIcon}
                recordRepresentation={(record) =>
                  `${record.last_name} ${record.first_name}`
                }
              />,
              <Resource
                name="candidate-qualifications"
                list={CandidatesQualificationList}
                icon={PersonIcon}
                recordRepresentation={(record) => `${record.name}`}
              />,
              <Resource
                name="candidate-types"
                list={CandidatesTypesList}
                icon={PersonIcon}
                recordRepresentation={(record) => `${record.name}`}
              />,
              <Resource
                name="account"
                list={AccountList}
                icon={BusinessIcon}
                recordRepresentation={(record) => `${record.name}`}
              />,
              <Resource
                name="project"
                list={ProjectList}
                icon={AppsIcon}
                recordRepresentation={(record) => `${record.name}`}
              />,
            ],
        <Resource
          name="candidate"
          list={CandidatesList}
          edit={CandidatesEdit}
          create={CandidatesCreate}
          show={CandidatesShow}
          icon={PostIcon}
          recordRepresentation={(record) =>
            `${record.surname} ${record.name} ${record.patronymic}`
          }
        />,
        <Resource
          name="vacancy"
          list={VacancyList}
          edit={VacancyEdit}
          create={VacancyCreate}
          icon={ArticleIcon}
        />,
        <Resource
          name="submits"
          list={SubmitList}
          create={SubmitCreate}
          edit={SubmitEdit}
          icon={SendIcon}
        />,
        <Resource
          name="artifacts"
          recordRepresentation={(record) => `${record.file_name}`}
        />,
      ]}
    </Admin>
  );
};

export default App;
